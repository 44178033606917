@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @layers responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
  }

html, body {
    color: #363636;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin: 0;
}

input[type='text']:focus {
    box-shadow: none;
}

h1 {
    @apply text-3xl sm:text-3.5xl font-semibold pb-4 leading-8;
}
h2 {
    @apply text-3xl font-semibold pb-4 leading-8;
}
h3 {
    @apply text-xl sm:text-2xl font-semibold pb-4 leading-8;
}
h4 {
    @apply text-lg sm:text-xl font-semibold pb-4 leading-8;
}
h5 {
    @apply text-base sm:text-lg font-semibold pb-4 leading-8;
}
h6 {
    @apply text-base font-semibold pb-4 leading-8;
}
.label {
    @apply block text-sm font-medium text-gray-700 mb-1;
}

.prevent-select {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }